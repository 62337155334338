.MainHeading1 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.FilterMainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FilterSubContainer {
    display: flex;
    column-gap: 15px;
    height: 40px;
}

.searchBar>div {
    border-radius: 30px;
    width: 450px;
}

.searchButton {
    background-color: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    text-transform: none !important;
}

.vertical {
    border-left: 1px solid #0000001f;
    height: 40px;
}

.MenuItems {
    text-transform: capitalize !important;
}

.TableHeaderRow {
    background-color: var(--table-header-background-color);
    height: 48px;
}

.TableClass th,
.TableClass td {
    height: 48px;
    padding: 0 16px !important;
}

.firstCell {
    padding-left: 15px !important;
}

.TableHeaderText {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: var(--side-nav-background-color);
}

.StatusChip {
    padding: 10px 8px !important;
}

.MenuItemText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    margin-left: 10px;
    color: var(--side-nav-background-color);
}

.brudcrumbs {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    cursor: pointer !important;
}

.TableBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.HeadingTextLable {
    font-weight: 500 !important;
}

.TextInput>div>input::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.refreshIcon {
    position: absolute;
    top: 26px;
    right: 0px;
    z-index: 111;
}

.copyIcon {
    position: absolute;
    right: -45px;
    top: 26px;
    z-index: 222;
}

.textLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.PassgenerateFunction {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
    background-color: var(--forgot-password-text-color) !important;
    width: 171px;
    height: 48px;
    border-radius: 70px !important;
}

.DropdownTextInput>div>div {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    text-align: left;
    text-transform: capitalize !important;
    color: var(--side-nav-background-color);
}

.defaultMenuItem>div,
.placeHolderText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
    opacity: 0.5;
}

.classFilterDropdown {
    width: 170px !important;
    text-align: left;
    text-transform: capitalize !important;
}

.resetPasswordText {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--forgot-password-text-color);
    margin-left: 10px;
    cursor: pointer;
}

.showPassword {
    position: absolute;
    bottom: 17px;
    right: 0;
}

.BrudcrumbContainer {
    display: flex;
    justify-content: space-between;
}

.editText {
    font-size: 16px;
    font-weight: 500;
}

.TableContainer {
    overflow-x: scroll !important;
}

.disabledBtn {
    opacity: 0.5;
    color: white !important;
}

.new_countrycode_field > div{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.01);
    border-radius: 9px;
}

.new_countrycode_field input:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg); */
    border-color: #1976d2 !important;
    border-width: 2px !important;
    /* outline: 0; */
    box-shadow: none !important; 
}
 .new_countrycode_field ul{
    width: 100% !important;
}
.err_text{
    color: #d32f2f !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

@media screen and (max-width: 767px) {
    .FilterMainContainer {
        row-gap: 10px;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .DividerClasses {
        display: none;
    }

    .FilterSubContainer {
        row-gap: 10px;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        height: unset !important;
    }

    .searchBar>div {
        width: 100%;
    }

    .searchButton {
        width: 100% !important;
    }

    .classFilterDropdown {
        width: 100% !important;
    }

}