.headerMainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    /* border-bottom: 1px solid var(--school-chip-color); */
}

.headerSubContainer {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.headerClearAllbtn {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    text-align: left;
    color: var(--forgot-password-text-color);
    text-transform: none !important;
}

.headerTitle {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.notificationBodyContainer {
    padding: 16px 20px;
}

.cardTopic {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
    display: flex;
    column-gap: 10px !important;
}

.cardMessage {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.cardTimeText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.dividerClass {
    border-left: 1px solid #DADEF2 !important;
}

.cardMaindiv {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 20px;
    cursor: pointer !important;
    position: relative;
}

.cardMaindiv:hover {
    background-color: #F0F1FC;
}

.hover_button_container {
    display: none;
    position: absolute;
    top: 32px;
    right: 0;
}

.cardMaindiv:hover .hover_button_container {
    display: block;
}