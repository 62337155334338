.TextInput>div {
    background-color: var(--white);
    text-align: left;
}

.TextInput {
    margin-bottom: 0 !important;
}

.formLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.formLableLight {
    color: var(--login-sub-header-text-color) !important;
}

.defaultMenuItem {
    margin-bottom: 5px !important;
}

.defaultMenuItem>div>div {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
    opacity: 0.5;
}

.FilterBtnContainer {
    display: flex;
    margin-top: 20px;
}

.schoolFilterDropdown>div {
    border-radius: 75px;
    width: 161px;
    text-align: left;
}

.FilterFindBtn {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: var(--forgot-password-text-color);
    text-transform: none !important;
    border-color: var(--forgot-password-text-color);
    padding: 5.5px 24px !important;
    border-radius: 70px !important;
    background: var(--white) !important;
    width: 118px;
    height: 40px;
}

.FilterSubContainer {
    display: flex;
    column-gap: 15px;
    margin-top: 20px;
    justify-content: flex-end;
}

.vertical {
    border-left: 1px solid #0000001f;
    height: 40px;
}

.searchButton {
    background-color: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    text-transform: none !important;
}

.TabsLableHeader {
    text-transform: none !important;
    color: var(--side-nav-background-color) !important;
}

.tabPanel>div {
    padding: 15px 0 !important;
}

.TabsHeaderDiv>div>div {
    column-gap: 30px !important;
}

.TabsHeaderDiv>div {
    overflow-x: scroll !important;
}

.TabsHeaderDiv>div::-webkit-scrollbar {
    display: none;
}

.searchBar>div {
    border-radius: 30px;
    width: 350px;
}

.sortDropdown {
    height: 40px;
    width: 161px;
}

.sortDropdown>div>div {
    text-align: left !important;
}

.TotalText {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.TableClass th,
.TableClass td {
    height: 48px;
    padding: 0 16px !important;
}

.TableHeaderRow {
    background-color: var(--table-header-background-color);
    height: 48px;
}

.TableHeaderText {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: var(--side-nav-background-color);
}

.StatusChip {
    padding: 10px 8px !important;
}

.MenuItemText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    margin-left: 10px;
    color: var(--side-nav-background-color);
}

.brudcrumbs {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    cursor: pointer !important;
}

.TableBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    color: var(--side-nav-background-color);
    text-transform: capitalize !important;
}

.TableBodyRow {
    cursor: pointer !important;
}

.topicDropdown {
    width: 90%;
    text-transform: capitalize !important;
}

.headingText {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}


.dragAndDropArea {
    display: flex;
    height: 38px;
    align-items: center;
    justify-content: center;
    background: var(--browse-area-background);
    border: 1px dashed var(--browse-border-color);
    border-radius: 5px !important;
}

.browseButton {
    height: 26px !important;
    width: 79px !important;
    background-color: var(--browse-btn-background) !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: center;
    padding: 0 !important;
    color: var(--forgot-password-text-color) !important;
    border-color: transparent !important;
}

.browseButton:hover {
    background-color: transparent !important;
    color: var(--forgot-password-text-color) !important;
}


.dragDropText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: left;
    margin-left: 10px !important;
    color: var(--side-nav-background-color);
}

.someNoteText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.addText {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: var(--forgot-password-text-color) !important;
    display: flex !important;
    align-items: center;
    column-gap: 6px;
    cursor: pointer !important;
    padding: 0 !important;
    text-transform: none !important;
}

.gameImage {
    width: 182px;
    height: 130px;
}

.fileExtension {
    width: 24px;
    height: 24px;
}

.BrudcrumbContainer {
    display: flex;
    justify-content: space-between;
}

.selectedTab {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
}

.TableContainer {
    overflow-x: scroll !important;
}

.SortByLable {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.firstCell {
    padding-left: 15px !important;
}

@media screen and (max-width: 767px) {

    .DividerClasses {
        display: none;
    }

    .FilterSubContainer {
        row-gap: 10px;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }


    .topicDropdown {
        width: 100%;
    }

    .FilterFindBtn {
        width: 100%;
    }

    .searchBar>div {
        width: 100%;
    }

    .schoolFilterDropdown {
        width: 100%;
    }

    .schoolFilterDropdown>div {
        width: 100%;
    }
}