.guestLayoutMainDiv {
    display: flex;
    padding: 15px;
    column-gap: 10px;
}

.imageContainer {
    position: relative;
}

.imageHeaderContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgb(255 255 255 / 75%);
    padding: 25px 25px 20px;
    width: 90%;
    border-radius: 32px 0 0 0;
}

.imageHeader {
    font-size: 28px !important;
    font-weight: 600 !important;
    line-height: 31px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.imageSubHeader {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}


.formContainerdiv {
    width: calc(59% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.formContainer {
    width: 75%;
    margin-top: 10%;
}

.imageContainerParent {
    width: 41%;
}

.mainLogo {
    position: absolute;
    width: 159px;
    height: 40px;
    top: 0;
    left: 15%;
}

.loginImage {
    height: 100%;
    width: 100%;
}

.welcomeHeadertext {
    font-size: 40px !important;
    font-weight: 600 !important;
    line-height: 60px !important;
    text-align: left !important;
    color: var(--login-primary-header-text-color);
}

.subHeaderText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
    color: var(--login-sub-header-text-color);
}

.form_lable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.passwordInputContainer {
    position: relative;
}

.showPassword {
    position: absolute;
    bottom: 17px;
    right: 0;
}

.forgotPassword {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: var(--forgot-password-text-color);
    cursor: pointer !important;
    text-transform: none !important;
}

.displayflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.loginButton {
    width: 204px !important;
    height: 56px !important;
    padding: 15px 48px 15px 48px !important;
    border-radius: 70px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    background-color: var(--forgot-password-text-color) !important;
    color: var(--white) !important;
}

.passwordStrongText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: var(--password-strong-text-color);
}

.TextInput>div>input::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--login-sub-header-text-color);
}


@media screen and (max-width: 1440px) {
    .loginImage {
        height: 95vh;
    }

    .imageContainerParent {
        width: 37%;
    }

    .formContainerdiv {
        width: calc(63% - 10px);
    }
}

@media screen and (max-width: 767px) {
    .imageContainerParent {
        display: none;
    }

    .formContainerdiv {
        width: 100%;
    }

    .formContainer {
        width: 95%;
        margin-top: 85px;
    }

    .mainLogo {
        left: 5%;
    }
}