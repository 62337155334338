.StudentPageMainHeading {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.TabsLableHeader {
    text-transform: none !important;
    color: var(--side-nav-background-color) !important;
}

.selectedTab {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
}

.TabsLableContainer>.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: center;
}

.searchButton {
    background-color: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    text-transform: none !important;
}

.tabPanel>div {
    padding: 0 0 !important;
}

.TableHeaderRow {
    background-color: var(--table-header-background-color);
    height: 48px;
}

.TableHeaderRow1 {
    height: 48px;
}

.TableHeaderText {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: var(--side-nav-background-color);
}

.TableBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    text-transform: capitalize;
    color: var(--side-nav-background-color);
}

.firstCell {
    padding-left: 15px !important;
}

.mainBtnText {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: var(--white);
}

.addBtnContainer {}

.searchBar>div {
    border-radius: 30px;
    width: 372px;
}

.schoolFilterDropdown {
    width: 200px;
}

.classFilterDropdown {
    width: 140px;
}

.gradeFilterDropdown {
    width: 140px;
}

.FilterMainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px !important;
}

.FilterSubContainer {
    display: flex;
    column-gap: 15px;
}

.StatusChip {
    padding: 10px 8px !important;
}

.MenuItemText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    margin-left: 10px;
    color: var(--side-nav-background-color);
}



.brudcrumbs {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    cursor: pointer !important;
}

.FormRadio span {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em !important;
    text-align: left;
}

.intrestDropDown>div>div {
    text-transform: capitalize !important;
}

.TableHeaderRow {
    background-color: var(--table-header-background-color);
    height: 48px;
}

.TableClass th,
.TableClass td {
    height: 48px;
    padding: 0 16px !important;
}

.PassgenerateFunction {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
    background-color: var(--forgot-password-text-color) !important;
    width: 171px;
    height: 48px;
    padding: 12px 48px;
    border-radius: 70px !important;
}

.refreshIcon {
    position: absolute;
    top: 26px;
    right: 0px;
    z-index: 111;
}

.HeadingTextLable {
    font-weight: 500 !important;
}

.passwordSentence {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.copyIcon {
    position: absolute;
    right: -45px;
    top: 26px;
    z-index: 222;
}

.NoteHeader {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.Notetext {
    font-weight: 400 !important;
    color: var(--login-sub-header-text-color);
}

.textLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.Studentprogress {
    background-color: var(--progressbar-default-background-color) !important;
    border-radius: 70px;
}

.Studentprogress>span {
    background-color: var(--student-progress-bar-background-color) !important;
    border-radius: 70px;
}

.resetPasswordText {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: var(--forgot-password-text-color);
    margin-left: 10px;
    cursor: pointer;
}

.GamesImages {
    width: 58px;
    height: 58px;
    margin-right: 10px;
}

.downloadCertificateText {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    text-align: left;
    color: var(--forgot-password-text-color);
}

.certificatesContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;
}

.textLable>span {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.SomeMoneyText {
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 39px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.SomeMoneyText>span {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: var(--login-sub-header-text-color);
}

.closeButton {
    position: absolute !important;
    right: 0;
}

.ModelContentContainer {
    padding: 40px 30px 32px;
}

.genereratePasswordtext {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    text-align: left;
    color: var(--forgot-password-text-color);
    cursor: pointer;
}

.ModelBtnSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ModelBtnSection button {
    width: 144px;
    height: 48px;
}

.cancelbutton {
    border: 1px solid var(--forgot-password-text-color);
    color: var(--forgot-password-text-color);
    text-transform: none !important;
    border-radius: 70px !important;
}

.savebutton {
    background: var(--forgot-password-text-color) !important;
    text-transform: none !important;
    border-radius: 70px !important;
}

.ChipContainer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 0 15px;
}

.ChipDeleteIcon {
    font-size: unset !important;
    margin: 0 0 1px 0 !important;
}

.DropdownTextInput>div>div {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    text-align: left;
    text-transform: capitalize !important;
    color: var(--side-nav-background-color);
}

.defaultMenuItem>div>div {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
    opacity: 0.5;
}

.BrudcrumbContainer {
    display: flex;
    justify-content: space-between;
}

.editText {
    font-size: 16px;
    font-weight: 500;
}

.certigicateCard {
    overflow-y: scroll;
    height: 100%;
}

.certigicateCard::-webkit-scrollbar {
    display: none;
}

.showPassword {
    position: absolute;
    bottom: 17px;
    right: 0;
}

.TableContainer {
    overflow-x: scroll;
}

.vertical {
    border-left: 1px solid #0000001f;
    height: 40px;
}

.AddStudentMainHEader {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left !important;
    color: var(--side-nav-background-color);
}

.dividerClass {
    border-right: 1px solid #DADAE1 !important;
    height: 16px;
}

.btnCardGrade {
    width: 49%;
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px !important;
    cursor: pointer !important;
    box-shadow: 0px 2px 16px 0px rgba(28, 8, 80, 0.10) !important;
}

.btnCardGrade>p {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    color: var(--login-sub-header-text-color);
}

.activeCard {
    border: 2px solid var(--forgot-password-text-color);
}

.activeCard>p {
    font-weight: 600 !important;
    color: var(--side-nav-background-color);
}

.downloadTemplteBtn {
    text-transform: capitalize !important;
    display: flex;
    column-gap: 5px;
    align-items: center;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: right;
    color: var(--forgot-password-text-color) !important;
}

.Informationcontainer {
    background-color: #BA9BFF1A;
    padding: 24px;
    border-radius: 8px;
    max-width: 420px;
    float: right;
}

.infoText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color) !important;
}

.infoText2 {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.reqiredFeildList {
    margin: 15px 0;
    padding-left: 12px;
}

.Searchbackground {
    background-color: var(--school-chip-color) !important;
    padding: 10px 20px;
    display: flex;
}

.teacherSearchbackground input {
    border-color: #46468F;
}

.toggleSearchBar>div {
    width: 260px;
}

.forgetPasswordmodalclose {
    position: absolute;
    top: 0;
    right: 0;
}

.successModalTableContainer {
    overflow-y: scroll;
    max-height: 350px;
}

.successModalTableContainer::-webkit-scrollbar {
    display: none;
}

.filterBtnsContainer {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.filterBtn {
    text-transform: none !important;
    display: flex;
    column-gap: 5px;
}

.disabledBtn {
    opacity: 0.5;
    color: white !important;
}

.new_countrycode_field > div{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.01);
    border-radius: 9px;
}

.new_countrycode_field input:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg); */
    border-color: #1976d2 !important;
    border-width: 2px !important;
    /* outline: 0; */
    box-shadow: none !important; 
}
 .new_countrycode_field ul{
    width: 100% !important;
}
.err_text{
    color: #d32f2f !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

  
@media screen and (max-width: 767px) {
    .FilterMainContainer {
        row-gap: 10px;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .FilterSubContainer {
        row-gap: 10px;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .searchBar>div,
    .toggleSearchBar>div {
        width: 100%;
    }

    .Searchbackground {
        display: grid;
    }

    .filterBtnsContainer {
        margin-top: 10px;
        justify-content: space-around;
    }

    /* .dividerClass {
        display: none;
    } */

    .searchButton {
        width: 100% !important;
    }

    .classFilterDropdown,
    .gradeFilterDropdown {
        width: 100% !important;
    }


    .addBtnContainer {
        position: unset;
        margin-bottom: 10px;
    }

    .textInvisible {
        display: none;
    }

    .SecondCard {
        margin-top: 16px;
    }

    .certificatesContainer {
        height: unset;
    }

    .tabsContainer>div {
        overflow-x: scroll !important;
    }

    .tabsContainer>div::-webkit-scrollbar {
        display: none;
    }

    .DividerClasses {
        display: none;
    }

    .downloadTemplteBtn {
        margin-top: 25px !important;
    }
}