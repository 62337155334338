.sideNavBarParent {
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
}

.sideNavBarParent::-webkit-scrollbar {
    display: none;
}

.headerParent {
    position: fixed;
    left: 240px;
    box-shadow: 0px 2px 8px 0px rgba(0, 56, 99, 0.20);
    width: calc(100% - 240px);
    /* z-index: 10; */
}

.MainBodyChildDiv {
    position: fixed;
    top: 60px;
    left: 240px;
    width: calc(100% - 240px);
    height: calc(100% - 70px);
    padding: 20px;
    background: var(--white);
    overflow-y: scroll;
    margin-top: 10px;
    padding-top: 10px;
}

.MainBodyChildDiv::-webkit-scrollbar {
    display: none;
}

.toggledHeaderParent,
.toggledMainBodyChildDiv {
    width: calc(100% - 72px);
    left: 72px;
}

.HeaderMaindiv {
    display: flex;
    column-gap: 5px;
    height: 60px;
    align-items: center;
}

.imageLogo {
    width: 159px;
    height: 40px;
    margin: auto;
    margin-top: 10px;
}

.sidenavLogoImage {
    display: block;
}

.sidenavListMainCointainer {
    min-height: 500px !important;
    position: relative !important;
}

.nabBarContainer {
    /* display: grid !important;
      align-content: space-around !important; */
    width: 100%;
    padding-left: 0;
}

.sidenavListMainCointainer li {
    list-style-type: none;
    width: 100%;
}

.sidenavListMainCointainer a {
    text-decoration: none;
}

.activebtn {
    border-left: 4px solid var(--forgot-password-text-color);
    background: linear-gradient(90deg, rgba(107, 117, 224, 0.30) 0%, rgba(107, 117, 224, 0.00) 100%);
    display: flex;
}

.activebtn .linkburron {
    margin-left: 0px !important;
}

.activebtn>li>button>span>svg>path {
    stroke: var(--progress-bar-direct-default-background-color) !important;
}

.sidebarText {
    margin-left: 8px !important;
    font-size: 16px !important;
}

.activebtn .sidebarText {
    font-weight: 600 !important;
}

.activebtn1 {
    border-left: 4px solid var(--forgot-password-text-color);
    display: flex;
    background: var(--side-nav-background-color) !important;
}

.activebtn1 .linkburron1 {
    margin-left: 0px !important;
    color: var(--white) !important;
}

.activebtn1>li>button>span>svg>path {
    stroke: var(--white) !important;
}

.linkburron1>span>svg>path {
    stroke: var(--side-nav-background-color) !important;
}

.activebtn1 .sidebarText {
    font-weight: 600 !important;
}

.linkburron {
    text-transform: none !important;
    color: var(--white) !important;
    display: flex !important;
    align-items: center !important;
    /* height: 50px; */
    margin-left: 4px !important;
    min-height: max-content;
    padding: 10px !important;
}

.linkburron1 {
    text-transform: none !important;
    color: var(--side-nav-background-color) !important;
    display: flex !important;
    align-items: center !important;
    /* height: 50px; */
    margin-left: 4px !important;
    min-height: max-content;
    padding: 10px !important;
}

.offcanvas {
    visibility: hidden !important;
}

.schoolOffcanvas {
    visibility: hidden !important;
}

.HeaderContainer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin: 0 20px;
}

.HeaderProfileContainer {
    display: flex !important;
    align-items: center !important;
    column-gap: 10px;
}

.HeaderText {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
    color: var(--side-nav-background-color);
}

.headerProfileDivider {
    width: 1px;
    height: 30px !important;
    color: var(--login-sub-header-text-color);
    border-width: 1px !important;
}

.HeaderProfileName {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
    text-transform: capitalize !important;
}

.MenuItemText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    margin-left: 10px;
    color: var(--side-nav-background-color);
}

.ProfileBtn {
    border: 1px solid var(--profile-btn-border-color) !important;
    width: calc(100% - 30px) !important;
    padding: 12px !important;
    border-radius: 8px !important;
    margin: auto !important;
    margin-top: 10px !important;
}

.ProfileBtnSmall {
    border: none !important;
    min-width: 0 !important;
}

.ProfileAvatarBtnText {
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    margin-left: 10px !important;
    text-align: left;
    color: var(--white);
}

.offcanvaCloseButton {
    min-width: 0 !important;
    position: absolute !important;
    top: 0px;
    right: 0px;
    font-weight: 600 !important;
    font-size: 25px !important;
}

.teacherCloseButton path {
    stroke: var(--white);
}

.offcanvasBody::-webkit-scrollbar {
    display: none !important;
}

.functionalheaderMaindiv {
    height: 60px;
    position: fixed;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 56, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
}

.functionalImageLogo {
    width: 159px;
    height: 40px;
    margin: 10px 5%;
}

.functionalCloseButton {
    position: absolute !important;
    top: 12px;
    right: 20px;
}

.functionalBodyMaindiv {
    position: fixed;
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    padding: 20px;
}

.smallImageLogo {
    height: 40px;
    margin: auto;
    margin-top: 10px;
}

@media screen and (max-width: 769px) {
    .offcanvas {
        visibility: visible !important;
        background: var(--side-nav-background-color) !important;
        width: 280px !important;
    }

    .schoolOffcanvas {
        visibility: visible !important;
        background: var(--school-side-nav-background-color) !important;
        width: 280px !important;
    }

    .sideNavBarParent {
        display: none;
    }

    .headerParent {
        width: 100%;
        left: 0;
    }

    .MainBodyChildDiv {
        width: 100%;
        left: 0;
    }

    .sidenavLogoImage {
        display: none;
    }

    .nabBarContainer li {
        padding-left: 0px;
    }

    .functionalImageLogo {
        display: block;
    }

    .HeaderProfileName {
        display: none;
    }

    .sidenavListMainCointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 80px);
        margin-bottom: 25px;
    }
}