body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Glory Variable' !important;
}

:root {
  --side-nav-background-color: #1C0850;
  --school-side-nav-background-color: #F0F1FC;
  --login-primary-header-text-color: #434B9F;
  --login-sub-header-text-color: #5D6892;
  --white: #fff;
  --default-app-background-color: #F5F7FA;
  --password-strong-text-color: #242628;
  --forgot-password-text-color: #6B75E0;
  --student-progress-bar-background-color: #6A5CF7;
  --progressbar-default-background-color: #E8E8E8;
  --progress-bar-direct-default-background-color: #FFC700;
  --progress-bar-search-default-background-color: #BA9BFF;
  --progress-bar-referal-default-background-color: #6AD7E0;
  --progress-bar-social-default-background-color: #FFADDB;
  --profile-btn-border-color: #6B75E033;
  --table-header-background-color: #E6E7EC;
  --active-chip-background: #00A57D1A;
  --inactive-chip-background: #EB57571A;
  --table-left-border-color: #DADAE1;
  --browse-btn-background: #F7F7F7;
  --browse-border-color: #DDDDDD;
  --browse-area-background: #F8F9FD;
  --school-chip-color: #5D68921A;
  --options-divider-color: #BDCEE9;
}