.headerCardContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}

.headerLeftContainer {
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: max-content;
}

.leftRightBtns {
    min-width: unset !important;
    padding: 0 !important;
}

.headerRightContainer {
    display: flex;
    align-items: center;
    border: 1px solid var(--school-chip-color);
    width: max-content;
    border-radius: 8px;
}

.headerbtns {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0em;
    text-transform: none !important;
    color: var(--side-nav-background-color) !important;
    height: 32px;
    padding: 0px 20px !important;
}

.activebtn {
    background-color: #6b75e080 !important;
}

.CalendarContainer a {
    text-decoration: none !important;
    color: var(--side-nav-background-color) !important;
}

.CalendarContainer th {
    border: none !important;
    border-left: none !important;
    text-align: left;
    padding-left: 10px;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 34px !important;
    letter-spacing: 0em;
    color: var(--side-nav-background-color);

}

.CalendarContainer tbody tr:first-child,
.CalendarContainer tbody tr:first-child td {
    border-top: 1px solid #ddd !important;
}

.calendarDateTextMain {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.forgetPasswordmodalclose {
    position: absolute;
    top: 10px;
    right: 10px;
}

.formInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.eventTitleText {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 21px;
    text-align: left;
    color: var(--side-nav-background-color);
}

.modalHeading {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.eventInput input {
    font-size: 15px;
}


.eventInput>div>div>div {
    height: 32px;
    padding: 0 6px !important;
}

.eventInput1 input {
    font-size: 15px;
}

.eventInput1 {
    width: 100% !important;
}

.eventInput1>div {
    height: 32px;
    padding: 0 !important;
}

.successModalSubHeading {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center;
    color: var(-login-sub-header-text-color);
}

.datePickerInput input {
    font-size: 15px;
}

.datePickerInput>div {
    height: 32px;
}

.multiInputFeild>div {
    height: 48px;
    padding: 5px !important;
}

.multiInputFeild {
    margin-bottom: 0 !important;
}

.multiInputFeild textarea {
    font-size: 15px;
    padding: 5px;
}

.saveBtn {
    width: 101px;
    height: 32px;
    border-radius: 75px !important;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}

.subcontentContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.subcontentItems {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.timeText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.viewModalBtnContainer {
    display: flex;
}

.viewModalBtn {
    display: flex;
    column-gap: 5px !important;
    width: 49%;
    padding: 0 !important;
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.errorBorder>div {
    border: 1px solid red !important;
}


.datePickerInput p {
    color: red !important;
}