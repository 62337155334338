.forgetPasswordmodalclose {
    position: absolute;
    top: 0;
    right: 0;
}

.ModelContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* width: 90%; */
    margin: auto;
    row-gap: 10px;
}

.successModalHeading {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    text-align: center;
    color: var(--side-nav-background-color);
}

.successModalSubHeading {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center;
    color: var(-login-sub-header-text-color);
}


.HeaderText {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
    color: var(--side-nav-background-color);
}

.logoutBtnContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.logoutBtnContainer button {
    width: 127px;
    height: 40px;
    border-radius: 75px;
    border: 1px solid var(--forgot-password-text-color);
    text-transform: capitalize !important;
}

.dashboardStatsBar {
    display: flex;
    column-gap: 15px;
    align-items: center;
    cursor: pointer !important;
}

.statisticsNumberText {
    font-size: 26px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    text-align: left !important;
    color: var(--side-nav-background-color);
}

.statisticsTitleText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.FooterMainDiv {
    width: 100%;
    background: white;
    position: fixed;
    bottom: 0px;
    padding: 10px 25px;
    left: 0;
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
}

.noDataImageContainer {
    display: flex;
    height: 70vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.noDataImage {
    width: 153px;
    height: 166.23px
}

.PaginationBtns li>button>svg>path {
    fill: var(--forgot-password-text-color);
}

.PaginationBtns li>button {
    color: var(--login-sub-header-text-color);
}

.SchoolActionModalsWidth {
    width: 410px;
}

.forgetPasswordmodalclose {
    display: flex;
    justify-content: flex-end;
}

.modalReminderClock>svg {
    width: 80px;
    height: 80px;
}

.reminderHeading {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    letter-spacing: 0em;
    text-align: center;
    color: var(--side-nav-background-color);
}

.ActiveModalHeader {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: 0em;
    text-align: center;
    color: var(--side-nav-background-color);
}

.btnSection {
    display: flex;
    column-gap: 15px;
}

.FooterBtn {
    width: auto !important;
    min-width: 140px !important;
    height: 40px !important;
    padding: 6px 24px !important;
    text-transform: none !important;
    border-radius: 70px !important;
}

.schoolFilterDropdown {
    text-align: left;
}

.schoolFilterDropdown>div {
    height: 40px;
}

.defaultMenuItem>div>div,
.placeHolderText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
    opacity: 0.5;
}

.MenuListItem {
    padding-left: 5px !important;
}

.dragAndDropArea {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    background: var(--browse-area-background);
    border: 1px dashed var(--browse-border-color);
    border-radius: 5px !important;
    width: 350px;
}

.MenuItemText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: 0em;
    text-align: left;
    color: #113741;
}

.browseButton {
    height: 26px !important;
    width: 79px !important;
    background-color: var(--browse-btn-background) !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: center;
    padding: 0 !important;
    color: var(--forgot-password-text-color) !important;
    border-color: transparent !important;
}

.browseButton:hover {
    background-color: transparent !important;
    color: var(--forgot-password-text-color) !important;
}

.someNoteText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.FileContainer {
    width: 100%;
    height: 48px;
    min-width: 350px;
    column-gap: 15px;
}

.fileCard {
    padding: 10px;
    width: 100%;
    height: 48px;
    justify-content: space-between;
    min-width: 350px;
    max-width: 350px;
    border-radius: 4px;
    border: 1px solid rgba(93, 104, 146, 0.20);
    background: var(--Table-Color, #F8F9FD);
}

.previewButton {
    height: 40px;
    width: 101px;
    border: 2px solid var(--forgot-password-text-color) !IMPORTANT;
    color: var(--forgot-password-text-color) !IMPORTANT;
    text-transform: capitalize !important;
    border-radius: 75px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
}

.formInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.headingText {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.formLable {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--side-nav-background-color);
}

.closebtn {
    position: absolute !important;
    right: 0;
}

.InputModalBtnSection {
    display: flex;
    justify-content: space-between;
}

.InputModalBtnSection button {
    width: 48%;
    height: 48px;
    border-radius: 75px;
    border: 2px solid #6B75E0 !important;
}

.cardConatainerforPie {
    padding: 30px;
    height: 120px;
    position: relative;
}

.iconSpan {
    position: absolute;
    top: 30px;
    right: 30px;
}

.arrow {
    content: "";
    position: absolute;
    border-width: 12px;
    left: -21px;
    border-style: solid;
    z-index: 10;
    transform: translateY(490%) !important;
    /* Remove box shadow */
}

.arrow {
    border-color: transparent #fff transparent transparent;
}

.arrow1 {
    content: "";
    position: absolute;
    border-width: 10px;
    left: -20px;
    border-style: solid;
    z-index: 1;
    transform: translateY(500%) !important;
    /* Remove box shadow */
}

/* Customize the arrow color and border color as needed */
.arrow1 {
    border-color: transparent #c2c2c29d transparent transparent;
}

.modalHeading {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.multiInputFeild>div {
    height: 48px;
    padding: 5px !important;
}

.multiInputFeild {
    margin-bottom: 0 !important;
}

.multiInputFeild textarea {
    font-size: 15px;
    padding: 5px;
}

.datePickerInput input {
    font-size: 15px;
}

.datePickerInput>div {
    height: 32px;
}

.eventInput input {
    font-size: 15px;
}


.eventInput>div>div>div {
    height: 32px;
    padding: 0 6px !important;
}

.saveBtn {
    width: 101px;
    height: 32px;
    border-radius: 75px !important;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 769px) {
    .dashboardStatsBar {
        padding: 10px 0 10px;
    }

    .DividerClasses {
        display: none;
    }

    .schoolFilterDropdown {
        width: 100% !important;
    }

    .FooterMainDiv {
        width: 100% !important;
        left: 0 !important;
    }

    .PaginationBtns ul {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

    .formControl {
        width: 100% !important;
    }

    .dragAndDropArea {
        width: 100% !important;
    }

    .fileCard {
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .FileContainer {
        min-width: 100% !important;
        max-width: 100% !important;
        flex-direction: column;
        height: unset;
        row-gap: 11px;
    }

    .previewButton {
        width: 100% !important;
    }
}