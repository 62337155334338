.cardMainHeading {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.viewAllBtn {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--forgot-password-text-color);
}

.memberNameText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.someOtherText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.progressBarContainer {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.progressBarStyles {
    height: 4px !important;
    width: 110px;
}

.progressBarStyles>div {
    background-color: var(--forgot-password-text-color);
}

.avatar {
    width: 12px;
    height: 12px;
}

.chartLabel {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.legendContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.chartCountNumber {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.chartCardMainHeading {
    font-size: 20px !important;
}

.doughnutChartContainer {
    display: grid;
    grid-template-columns: 55% 45%;
    align-items: center;
}

.gameCard {
    padding: 15px;
    height: 181px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.GameHeading {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.GameDiamond {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
}

.GameMainImage {
    position: absolute;
    bottom: 0;
    right: 0;
}

.MenuButton {
    z-index: 55;
    position: absolute !important;
    top: 5px;
    right: 0;
}

.teacherDashboardCard1 {
    position: relative;
    min-height: 160px;
    display: flex;
    align-items: center;
    padding-left: 30px !important;
}

.teacherDashboardHeader {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 45px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.teacherDashboardSubHeading {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.teacherDashboardImage {
    position: absolute;
    width: 170px;
    top: 0px;
    right: 15px;
}

.calendarCard {
    margin-top: 10px;
    padding: 10px;
}

.dashboardCalendarContainer {
    background-color: #1C08501A;
}

.calendarDivider {
    border-top: 1px solid #00000080 !important;
    margin-top: 10px !important;
}

.teacherDashboardviewAllBtn {
    position: absolute;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--forgot-password-text-color);
    cursor: pointer;
    top: 0px;
    right: 10px;
}

.scheduleCard {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.scheduleDivider {
    border-left: 1px solid #434B9F;
    width: 0;
    height: 80px !important;
}

.seduleSubcontainer1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 80px;
    padding: 15px 0;
}

.sheduleBigtext {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.sheduleSmalltext {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 13px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
    display: flex;
    justify-content: space-around;
    width: 100%;
}


@media screen and (max-width: 767px) {
    .doughnutChartContainer {
        grid-template-columns: 100%;
    }

    .legendContainer {
        display: flex;
        /* width: 100%; */
        justify-content: space-between;
        align-items: center;
        column-gap: 30px;
        flex-direction: row;
    }
}