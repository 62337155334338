.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.red>span {
  background-color: red;
}

.darkred>span {
  background-color: darkred;
}

.orangered>span {
  background-color: orangered;
}

.darkorange>span {
  background-color: darkorange;
}

.orange>span {
  background-color: orange;
}

.gold>span {
  background-color: gold;
}

.darkkhaki>span {
  background-color: darkkhaki;
}

.lightgreen>span {
  background-color: lightgreen;
}

.green>span {
  background-color: green;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.custom-placeholder::placeholder {
  color: rgba(0, 0, 0, 0.3); 
}

.custom-placeholder:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.custom-placeholder::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}