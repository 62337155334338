.ChatMainDiv {
    height: calc(100vh - 100px);
    display: grid;
    grid-template-columns: 30% 70%;
}

.ContactListMainContainer {
    border-right: 1px solid var(--school-chip-color);
}

.ContactListHeader {
    padding: 16px 20px;
}

.ContactListBody {
    overflow-y: scroll;
    height: calc(100vh - 175px);
}

.ContactListBody::-webkit-scrollbar {
    display: none;
}

.ContactcontenrCard {
    display: flex;
    column-gap: 10px;
    padding: 10px 20px;
    align-items: center;
    cursor: pointer;
}

.ContactcontenrCard:hover {
    background-color: #6B75E01F;
}

.ContactcontenrCardActive {
    background-color: #6B75E01F;
}

.cardProfile {
    width: 48px;
    height: 48px;
}

.chatHeaderProfile {
    width: 40px;
    height: 40px;
}

.CardName {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.Divider {
    border: 0.5px solid var(--school-chip-color);
    opacity: 0.75;
    border-width: 0 0 1px 0;
    width: calc(100% - 40px);
    margin: 10px auto;
}

.CardTime {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.chatHeaderProfileDiv {
    padding: 16px 20px;
    border-bottom: 1px solid var(--school-chip-color);
    position: relative;
}

.chipText {
    background: #3BB765;
    color: white;
    padding: 2px 12px;
    font-family: Glory;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.ChatBody {
    height: calc(100vh - 259px);
}

.InputAdornmentContainer {
    column-gap: 5px;
}

.ChatFooter {
    width: 100%;
    padding: 15px 2%;
}

.ChatInput>div {
    height: 56px;
}