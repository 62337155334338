.TabsLableHeader {
    text-transform: none !important;
    color: var(--side-nav-background-color) !important;
}

.tabPanel>div {
    padding: 15px 0 !important;
}

.TabsHeaderDiv>div>div {
    column-gap: 30px !important;
    overflow-x: scroll;
}

.TabsHeaderDiv>div>div::-webkit-scrollbar {
    display: none;
}

.roleSchoolSelector>div>div>div {
    background-color: var(--white) !important;
}

.formLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.FilterContainerMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.HeadertextFeildContainer {
    display: flex;
    column-gap: 20px;
}

.TextInput>div>input::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.TextInput>div>div {
    background-color: var(--white);
    text-align: left;
    text-transform: capitalize;
}

.TextInput>div {
    background-color: var(--white);
}

.defaultMenuItem>div>div {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
    opacity: 0.5;
}

.searchBar>div {
    border-radius: 30px;
    width: 350px;
}

.searchButton {
    background-color: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    text-transform: none !important;
    min-width: 110px;
    height: 40px;
}

.nrmlBtnText {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: var(--white);
}

.vertical {
    border-left: 1px solid #0000001f;
    height: 40px;
}

.mainBtnText {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: var(--white);
}

.TableClass th,
.TableClass td {
    height: 48px;
    padding: 0 16px !important;
}

.TableHeaderRow {
    background-color: var(--table-header-background-color);
    height: 48px;
}

.firstCell {
    padding-left: 15px !important;
}

.brudcrumbs {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    cursor: pointer !important;
}

.TableHeaderText {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: var(--side-nav-background-color);
}

.TableBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.addText {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: var(--forgot-password-text-color) !important;
    display: flex !important;
    align-items: center;
    column-gap: 6px;
    cursor: pointer !important;
    padding: 0 !important;
    text-transform: none !important;
}

.defaultMenuItem {
    margin-bottom: 7px !important;
}

.TableContainerHeader {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.CheckboxHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tableAddRole td {
    height: 48px !important;
    padding: 0 16px;
}

.tableAddRole th,
.tableAddRole td {
    border-right: 1px solid rgba(224, 224, 224, 1);
}


.TableHeaderRowCheck {
    border-top: 1px solid rgba(224, 224, 224, 1);
}

.selectedTab {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
}

.TableContainer {
    overflow-x: scroll !important;
}

@media screen and (max-width: 767px) {
    .HeadertextFeildContainer {
        row-gap: 13px;
        flex-direction: column;
        width: 100%;
    }

    .FilterContainerMain {
        row-gap: 13px;
        width: 100%;
        flex-direction: column;
        align-items: unset;
    }

    .searchBar>div {
        width: 100%;
    }

    .searchButton {
        width: 100%;
    }

    .DividerClasses {
        display: none;
    }

    .accessLevelContainer {
        margin-top: 25px !important;
        margin-bottom: 10px;
    }

}