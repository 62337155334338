.brudcrumbs {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    cursor: pointer !important;
}

.TableHeaderText {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.TableBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    text-transform: capitalize;
    color: var(--side-nav-background-color);
}

.HeadingTextLable {
    font-weight: 500 !important;
}

.cardHeader1 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.SomeImportantText {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.cardHeaderContainer1 {
    background-color: var(--progress-bar-social-default-background-color) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
}

.subscrtionsListCardHeader {
    padding: 20px;
}

.cardHeader2 {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 27px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.cardSubHeader2 {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 27px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.cardHeader3 {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.headerSubText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    letter-spacing: 0em;
    text-align: left;
}

/* .paymentDetailsMainDiv {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
} */

.paymentListDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.paymentSubText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color) !important;
}

.paymentTextWeight {
    font-weight: 500 !important;
}

.applyButton {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: right;
    color: var(--forgot-password-text-color) !important;
    cursor: pointer;
}

.remove {
    margin-left: 10px;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.MethodsListContainer {
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.MethodTxt {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.methodCard {
    display: flex;
    flex-direction: column;
    background-color: #ffc7001a !important;
    padding: 15px;
    height: 74px;
    justify-content: center;
    row-gap: 5px;
    border-radius: 12px !important;
}

.formLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.cardMethodBox {
    width: 27% !important;
}

.TextInput {
    margin-bottom: 0 !important;
}

.TextInputCvv {
    display: flex !important;
    margin-bottom: 0 !important;
}

.TextInputCvv>div {
    width: 92% !important;
}

.questionsvg {
    position: absolute;
    bottom: 10px;
    right: 15px;
}

.forgetPasswordmodalclose {
    position: absolute;
    top: 0;
    right: 0;
}

.MainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    row-gap: 40px;
}

.ReceiptMainHeading {
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 45px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color) !important;
}

.HeaderContainer {
    display: flex;
    justify-content: space-between;
    padding: 25px 30px;
    background-color: #ffc7001a !important;
}

.ReceiptMainContainer {
    padding: 30px 30px;
}

.ReceiptMainContainer1 {
    padding: 20px 30px;
}

.ReceiptSubHeaderContainer {
    padding: 15px 30px;
    background-color: #ba9bff1a;
}

.DownloadReceipt {
    width: 242px;
    height: 54px;
    border-radius: 126px !important;
    border-width: 3px !important;
    box-shadow: 0px 2px 8px 0px rgba(107, 117, 224, 0.50);
    font-size: 19px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
}

.ReceiptbtnContainer {
    display: flex;
    column-gap: 15px;
}

.subscMainHeading {
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 45px !important;
    letter-spacing: 0em;
    text-align: center;
    color: var(--side-nav-background-color) !important;
}

.subscriptionListContainer {
    display: flex;
    justify-content: center;
    row-gap: 30px;
}

.subscriptionbtn {
    width: 225px;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: 1px !important;
    position: absolute !important;
    border-radius: 75px !important;
    text-transform: capitalize !important;
    bottom: -18px;
    left: 49px;
}

.subscrtionsListCardContainer {
    border-radius: 24px !important;
    min-height: 410px;
    position: relative;
}

.descriptionList {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.activeCard {
    box-shadow: 0px 0px 8px 1px rgb(66 10 230 / 97%) !important;
}

.cardNumberInput {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
}

.cardImage {
    position: absolute;
    top: 40px;
    right: 10px;
}

.ErrorText {
    color: red !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    display: flex;
}

.CouponInput {
    margin-bottom: 0 !important;
}

.CouponInput>div {
    border-radius: 12px;
    background: #FAFAFF;
    box-shadow: 0px 4px 4px 0px #E3F0FF;
}

.Couponcontainer {
    justify-content: space-between !important;
    padding: 10px 15px;
    background-color: #6ad7e01a;
}

.closeButton {
    min-width: 0px;
    margin-left: 10px;
    padding: 0;
}

@media screen and (max-width: 767px) {
    .TextInputCvv {
        width: 85% !important;
    }

    .cardMethodBox {
        width: auto !important;
    }

}