.PageMaindiv {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.FilterContainerMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.HeadertextFeildContainer {
    display: flex;
    column-gap: 20px;
}

.searchBar>div {
    border-radius: 30px;
    width: 350px !important;
}

.nrmlBtnText {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: var(--white);
}

.sortDropdown {
    height: 40px;
    width: 250px;
}

.sortDropdown>div>div {
    text-align: left !important;
}

.searchButton {
    background-color: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    text-transform: none !important;
    min-width: 110px;
    height: 40px;
}

.mainBtnText {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: var(--white);
}

.vertical {
    border-left: 1px solid #0000001f;
    height: 40px;
}

.TextInput>div>input::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.SectionsRowFirstTab {
    border-left: 1px solid var(--table-left-border-color);
    /* text-transform: capitalize !important; */
}

.MenuItemText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    margin-left: 10px;
    color: var(--side-nav-background-color);
}

.TableHeaderRow {
    background-color: var(--table-header-background-color);
    height: 48px;
}

.TableClass th,
.TableClass td {
    height: 48px;
    padding: 0 16px !important;
}

.TableBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.TableHeaderText {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: var(--side-nav-background-color);
}

.firstCell {
    padding-left: 15px !important;
}

.btnCardGrade {
    width: 32%;
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px !important;
    cursor: pointer !important;
    box-shadow: 0px 2px 16px 0px rgba(28, 8, 80, 0.10) !important;
}

.errorText {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: red !important;
}

.activeCard {
    border: 2px solid var(--forgot-password-text-color);
}

.btnCardGrade>p {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    color: var(--login-sub-header-text-color);
}

.activeCard>p {
    font-weight: 600 !important;
    color: var(--side-nav-background-color);
}

.PageSubMaindiv {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.brudcrumbs {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    cursor: pointer !important;
}

.textLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.ListItemText>span {
    text-transform: capitalize !important;
}

.defaultMenuItem {
    margin-bottom: 5px !important;
    text-transform: capitalize !important;
}

.defaultMenuItem>div>div {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
    text-transform: capitalize !important;
    opacity: 0.5;
}

.defaultSelectValue {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.TextInput>div {
    background-color: var(--white);
    text-align: left;
    height: 40px;
    width: 185px;
}

.TextInput1>div {
    background-color: var(--white);
    text-align: left;
    height: 40px;
}

.noDataImageContainer {
    display: flex;
    height: 70vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TableContainer {
    overflow-x: scroll !important;
}

.noDataImage {
    width: 153px;
    height: 166.23px
}

.StatusChip {
    padding: 10px 8px !important;
}

.BrudcrumbContainer {
    display: flex;
    justify-content: space-between;
}

.sectionChipContainer {
    margin-top: 10px;
    display: flex;
    column-gap: 10px;
}


@media screen and (max-width: 767px) {
    .CardContainer {
        margin-bottom: 60px;
    }

    .noDataImageContainer {
        height: 55vh;
    }

    .HeadertextFeildContainer {
        row-gap: 13px;
        flex-direction: column;
        width: 100%;
    }

    .FilterContainerMain {
        row-gap: 13px;
        width: 100%;
        flex-direction: column;
    }

    .searchBar>div {
        width: 100%;
    }

    .searchButton {
        width: 100%;
    }

    .btnContainer {
        width: 100%;
    }

    .sortDropdown {
        width: 100%;
    }

    .DividerClasses {
        display: none;
    }

    .TextInput>div {
        width: 100% !important;
    }
}